@import "../styles/mixin";

.qrcode {
  position: fixed;
  z-index: 9999;
  width: 150px;
  height: 190px;
  left: 50%;
  transform: translateX(-50%);
  //아래는 원래 스타일
  //right: 40px;
  //bottom: 180px;
  bottom: 100px;
  border-radius: 20px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity .3s ease-in-out;

  .image {
    width: 93px;
    height: 93px;
    margin-top: 28px;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .guide {
    padding: 5px 11px 6px 9px;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    margin-top: 15px;
    width: 93px;
    text-align: center;
    background-color: #24252b;
  }

  &.isVisible {
    opacity: 1;
  }
}

.toSafari {
  position: fixed;
  z-index: 9999;
  right: 25px;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .copyMessage {
    color: #2d3436;
    border: none;
    font-size: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 7.5px 10px;
    margin-bottom: 10px;
    background: #fff;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity .3s cubic-bezier(0.61, 1, 0.88, 1), transform .3s cubic-bezier(0.61, 1, 0.88, 1);

    &.isVisible {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  button {
    appearance: none;
    border-radius: 4px;
    padding: 7.5px 10px;
    background: #2e08f6;
    border: none;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }
}

.qrcodeButton {
  position: fixed;
  z-index: 9999;
  @include scale(width, 461.4px);
  @include scale(height, 48.3px);
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  align-content: center;
  background-image: url(../static/images/saytouche-btn.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);

  svg {
    vertical-align: middle;
  }
  right: 40px;
  bottom: 40px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  border: solid #efefef 1px;
}
