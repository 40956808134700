* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'LL Circular', 'Apple SD Gothic Neo', sans-serif !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-user-drag: none;
  background: #f3f3f3;
}

html,
body,
model-viewer {
  position: relative;
  width: 100%;
  height: 100%;
}

a,
button {
  cursor: pointer;
  align-items: center;
  display: flex;
  color: inherit;
  text-decoration: none;
}

slot[name="ar-button"] {
  display: none !important;
}
