@import "../styles/mixin";

.arButton {
  position: fixed;
  z-index: 9999;
  @include scale(width, 461.4px);
  @include scale(height, 48.3px);
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  align-content: center;
  background-image: url(../static/images/saytouche-btn.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);

  svg {
    vertical-align: middle;
  }
  right: 40px;
  bottom: 40px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  border: solid #efefef 1px;
}

.confirm {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  display: flex;

  button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    appearance: none;
    border-radius: 4px;
    padding: 10px 12.5px;
    background: #2e08f6;
    border: none;
    color: #fff;
    font-size: 14px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }
}

